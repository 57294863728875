import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import { addAndRemoveDisabledCls, isValidEmail } from '../utils/utils';

const InventorResources = () => {
    const seo = {
        metaDesc: 'Inventor Resources - Inventiv.org'
    }
  //
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  //
  const submit = async (e) => {
    e.preventDefault();

    if (email && isValidEmail(email)) {
        // call api
        const data = {
            email: email,
            name: `${firstName} ${lastName}`,
            phone: '',
            subject: '[Inventiv] New sign up from ' + email,
            message: `New sign up from  ${email}`,
            site: 'inventiv.org'
        }
        fetch("https://api.trustyip.com/subcribers/add", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {
            alert('Thank you for contacting us. We will follow up with you soon');
            // reset
            setEmail('');
            setFirstName('');
            setLastName('');
            setEmailSent(true);
        })
        .catch(err => {
            alert('There is something wrong. Please try again later!');
            console.log(err);
        });
        //
        addAndRemoveDisabledCls('signUpBtn');
    } else {
        alert('Please enter your valid email.');
    }

  }
    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Inventor Resources - Inventiv.org" canonical='/inventor-resources' seo={seo} />
            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Inventor Resources</h1>
                    </div>
                </div>
            </div>
            {/* <!-- Rsvp start --> */}
            <div class="container mt-3 mb-3">
                <video class="" id="videoPlayer" width="100%" poster="/assets/img/banner/have-a-great.png" preload="metadata" controls>
                    <source src="https://wp.inventiv.org/wp-content/uploads/2023/07/inventiv-ad-2.mp4" type="video/mp4" />
                </video>
            </div>

            <div class="rsvp content-area-2" id="rsvp">
                <div class="container">

                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <h4 class="">Many inventors worry about not having money to file patents. Worry no more, Inventiv is providing software to draft Provisional Applications.

                            </h4>
                            <p class="f-16">
                                The software integrates patent search engines with structured guidance through each section of the application. In addition, Inventiv provides educational resources and links for inventors to get help from the USPTO inventor assistance center and probono program from law schools.

                            </p>
                            <p class="f-16">
                                Receive an invitation to use our ProvisionalBuilder software by filing your information in the following form.
                            </p>
                            <p class="f-16">
                                Inventiv is a 501c3 non-profit. Our Tax ID is 83-0668793.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <form action="#" method="GET" enctype="multipart/form-data">
                                <div class="row">
                                    <h4 class="sec-clr" style={{ paddingLeft: 15 + 'px' }}>Enter your information to try out ProvisionalBuilder cloud-based software
                                    </h4>
                                    <div class="col-md-12 col-12">
                                        <div class="form-group name">
                                            <input type="text" name="name" class="form-control" placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <div class="form-group name">
                                            <input type="text" name="name" class="form-control" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <div class="form-group email">
                                            <input type="email" name="email" class="form-control" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-12">
                                        <div class="send-btn text-center">
                                            <button id='signUpBtn' type="button" class="btn btn-default btn-md btn-message" onClick={submit}>Send</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Rsvp end --> */}
        </Layout>
    )
};

export default InventorResources;